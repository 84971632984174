<template>
  <cw-page
    icon="business"
    class="cw-sme-pos-company-information"
  >
    <template #hero-title>
      <translate>
        Company's basic information
      </translate>
    </template>
    <validation-observer
      ref="observer"
      autocomplete="off"
      tag="v-form"
    >
      <div
        class="
          cw-border-container
          cw-border-container--primary
        "
      >
        <v-row>
          <v-col cols="12" md="6">
            <p>
              <translate>
                Fill in the company information together with the customer. Ask the customer to
                confirm the information before proceeding with the application.
              </translate>
            </p>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6">
            <cw-text-field
              id="companyName"
              v-model="companyName"
              :hint="companyNameHint"
              :label="companyNameLabel"
              rules="required"
              type="text"
              disabled
            />
          </v-col>

          <v-col cols="12" sm="6">
            <cw-text-field
              id="companyEmail"
              v-model="form.companyEmail"
              :hint="companyEmailHint"
              :label="companyEmailLabel"
              rules="required|email"
              type="email"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <cw-text-field
              id="companyPhone"
              v-model="form.companyPhone"
              :hint="companyPhoneHint"
              :label="companyPhoneLabel"
              rules="required|phone"
              type="tel"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <cw-text-field
              id="turnoverInLastMonth"
              v-model="form.turnoverInLastMonth"
              :label="turnoverInLastMonthLabel"
              lang="en-150"
              rules="required"
              type="number"
              currency-input
            />
          </v-col>

          <v-col cols="12" sm="6">
            <cw-text-field
              id="profitInLastMonth"
              v-model="form.profitInLastMonth"
              :label="profitInLastMonthLabel"
              lang="en-150"
              rules="required"
              type="number"
              currency-input
            />
          </v-col>

          <v-col cols="12" sm="6">
            <cw-text-field
              id="employeeCount"
              v-model="form.employeeCount"
              :label="employeeCountLabel"
              rules="required"
              type="number"
            />
          </v-col>
        </v-row>
      </div>

      <v-row>
        <v-col class="mt-6" cols="12">
          <h3 class="text-body-2 font-weight-bold mb-2">
            Laskutusosoite
          </h3>
          <p class="ma-0">
            Laskut lähetetään postitse, joten täytä tähän yrityksesi postiosoite.
          </p>
        </v-col>

        <v-col cols="12" sm="6">
          <cw-text-field
            id="billingAddress"
            v-model="form.billingAddress"
            :label="billingAddressLabel"
            rules="required"
            type="text"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <cw-text-field
            id="billingZip"
            v-model="form.billingZip"
            :label="billingZipLabel"
            rules="required"
            type="text"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <cw-text-field
            id="billingCity"
            v-model="form.billingCity"
            :label="billingCityLabel"
            rules="required"
            type="text"
          />
        </v-col>
      </v-row>
    </validation-observer>

    <template #actions>
      <v-spacer/>
      <v-btn
        id="submit"
        :dark="!$wait.waiting('SUBMIT_FORM')"
        :disabled="$wait.waiting('SUBMIT_FORM')"
        :loading="$wait.waiting('SUBMIT_FORM')"
        color="purple-one darken-2"
        @click="
          validateForm();
          $eventLogger.clickEvent($event);
        "
      >
        <translate>Continue</translate>
      </v-btn>
    </template>
  </cw-page>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import { mapActions } from 'vuex';
import { goToError } from '@shared/mixins';
import { DataLayer } from '@shared/services/dataLayer';
import items from '@fi/pages/steps/data/companyInformation';

export default {
  name: 'CwBusinessLoanCompanyInformation',

  components: {
    'cw-text-field': () => import('@shared/components/inputs/CwTextField'),
    'validation-observer': ValidationObserver,
  },

  mixins: [goToError],

  data: () => ({
    companyName: null,
    form: {
      billingAddress: null,
      billingCity: null,
      billingZip: null,
      companyEmail: null,
      companyPhone: null,
      employeeCount: null,
      profitInLastMonth: null,
      turnoverInLastMonth: null,
    },
    items,
  }),

  computed: {
    /**
     * Gettext translations
     */
    companyNameHint: vm => vm.$gettext('Name of the company'),
    companyNameLabel: vm => vm.$gettext('Name'),
    companyEmailHint: vm => vm.$gettext("Company's email"),
    companyEmailLabel: vm => vm.$gettext('Email'),
    companyPhoneHint: vm => vm.$gettext("Company's phone number"),
    companyPhoneLabel: vm => vm.$gettext('Phone number'),
    turnoverInLastMonthLabel: vm => vm.$gettext("Last month's turnover"),
    profitInLastMonthLabel: vm => vm.$gettext("Last month's result"),
    employeeCountLabel: vm => vm.$gettext('The number of employees'),
    billingAddressLabel: vm => vm.$gettext('Street address'),
    billingZipLabel: vm => vm.$gettext('Postal code'),
    billingCityLabel: vm => vm.$gettext('Postal district'),
  },

  methods: {
    ...mapActions({
      submitForm: 'application/submit',
    }),

    async submit() {
      try {
        this.$wait.start('SUBMIT_FORM');
        await this.submitForm({
          formParams: this.form,
          routineParams: {
            uri: 'executeRoutine',
            routine: 'Submit Company Information',
          },
        });
      } catch (e) {
        this.$wait.end('SUBMIT_FORM');
      }
    },

    async validateForm() {
      const valid = await this.$refs.observer.validate();
      this.$dataLayer.PushToECommerce({ params: { action: 'b2bStepValidationClick' } });

      DataLayer.onValidationClick();

      if (!valid) {
        this.goToError();
        DataLayer.onValidationFail();

        return;
      }

      this.$dataLayer.PushToECommerce({ params: { action: 'b2bStepValidationSubmit' } });
      await this.submit();
    },
  },
};
</script>
